"use client"

import { format } from "date-fns"
import { DateRange } from "react-day-picker"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button/Button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover/Popover"
import { CalendarIcon } from "lucide-react"
import { Calendar } from "./Calendar"

interface RangeDatePickerProps {
  className?: string
  selected: { from: Date | undefined; to: Date | undefined }
  onChange: (range: { from: Date | undefined; to: Date | undefined }) => void
}

export function RangeDatePicker({ className, selected, onChange }: RangeDatePickerProps) {
  const handleSelect = (range: DateRange | undefined) => {
    const from = range?.from || undefined
    const to = range?.to || undefined
    onChange({ from, to })
  }

  const currentYear = new Date().getFullYear();

  return (
    <div className={cn("grid gap-2 w-[300px]", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start text-left font-normal",
              !selected && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {selected.from ? (
              selected.to ? (
                <>
                  {format(selected.from, "LLL dd, y")} - {format(selected.to, "LLL dd, y")}
                </>
              ) : (
                format(selected.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">

          <Calendar
            mode="range"
            captionLayout="dropdown-buttons"
            fromYear={currentYear - 4}
            toYear={currentYear}
            selected={selected}
            onSelect={handleSelect}
            numberOfMonths={1} 
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}
